import TextBanner from '@/components/ui/organisms/text-banner/text-banner'
import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'

const BannerText = ({
	aboveTheFold,
	minHeight,
	title,
	titleTag,
	titleColor,
	titleColorMobile,
	ctas,
	horizontalAlign,
	sx,
	cssProp,
	className,
	seoBannerId,
	seoBannerName,
	seoBannerCreative,
	seoBannerPosition,
}) => {
	const links = useLinks([
		{ type: ctas?.[0]?.linkType, href: ctas?.[0]?.link },
		{ type: ctas?.[1]?.linkType, href: ctas?.[1]?.link },
		{ type: ctas?.[2]?.linkType, href: ctas?.[2]?.link },
	])

	const { promotionClick } = useTrackingsActions()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}

	return (
		<TextBanner
			className={className}
			css={cssProp}
			aboveTheFold={aboveTheFold}
			horizontalAlign={horizontalAlign}
			sx={{ ...sx, minHeight, height: aboveTheFold ? '100%' : undefined }}
			title={title}
			titleProps={{ component: titleTag, sx: { color: { xs: titleColorMobile, md: titleColor } } }}
			cta={ctas?.map(({ variant, text, color, openInNewTab }, i) => ({
				variant,
				label: text,
				color,
				colorHex: titleColor,
				url: links[i],
				target: openInNewTab ? '_blank' : '_self',
				onClick: (e) => {
					if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
						trackClickEvent({
							event: e,
							id: seoBannerId,
							name: seoBannerName,
							creative: seoBannerCreative,
							position: seoBannerPosition,
						})
					}
				},
			}))}
		/>
	)
}

export default BannerText

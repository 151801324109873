import { Root, Title, CtaContainer, Button } from '@/components/ui/organisms/text-banner/text-banner.styled'
import { cx } from '@emotion/css'

const TextBanner = ({ aboveTheFold, title, titleProps, horizontalAlign, cta, className, ...props }) => {
	return (
		<Root
			aboveTheFold={aboveTheFold}
			className={cx('Text-Banner', className)}
			justifyContent={horizontalAlign}
			{...props}
		>
			{title && (
				<Title variant="headlineTextBanner" {...titleProps}>
					{title}
				</Title>
			)}
			<CtaContainer>
				{cta.map(({ label, url, variant = 'outlined', ...rest }, key) => (
					<Button href={url} variant={variant} {...rest} key={key}>
						{label}
					</Button>
				))}
			</CtaContainer>
		</Root>
	)
}

export default TextBanner

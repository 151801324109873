import Typography from '@bluheadless/ui/src/atoms/typography'
import BHButton from '@bluheadless/ui/src/molecules/button'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) => {
	return !['justifyContent', 'aboveTheFold', 'colorHex'].includes(prop)
}

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.black};
`

const Root = styled('div', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
	text-align: ${({ justifyContent }) => textAlign(justifyContent)};
	max-width: 700px;
	margin: auto;
	width: 100%;

	&.title-alternative {
		${Title} {
			font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
		}
	}
`

const CtaContainer = styled('div')`
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
	margin: ${({ theme }) => theme.spacing(5)} auto 0;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
	}
`

const Button = styled(BHButton, { shouldForwardProp })`
	background: unset;
	backdrop-filter: unset;
	color: ${({ colorHex }) => colorHex};
	border-color: ${({ colorHex }) => colorHex};
	&:hover {
		color: ${({ colorHex }) => colorHex};
		border-color: ${({ colorHex }) => colorHex};
		background: unset;
	}
`

export { Root, Title, CtaContainer, Button }
